"use client";
import Link from "next/link";
import { useState } from "react";
import ContactUs from "../ContactForm";
import LogoAndSocialMedia from "./LogoAndSocialMedia";
import Newsletter from "./Newsletter";
import AdvertiseOnRodeyo from "../AdvertiseForm";
import Alert from "../Alert";
import Modal from "../Modal";

const navigation = {
  solutions: [
    { name: "Launchpad", href: "/launchpad", target: "_self" },
    { name: "Mint", href: "/create", target: "_self" },
    // { name: "Sell", href: "/create", target: "_self" },
    // { name: "Analytics", href: "/launchpad" },
    // { name: "Marketing", href: "/launchpad" },
  ],
  support: [
    {
      name: "Pricing",
      href: "https://forum.rodeyo.com/t/about-the-fees-category/10",
    },
    { name: "Forum", href: "https://forum.rodeyo.com/" },
    { name: "Guides", href: "https://forum.rodeyo.com/c/guides/7" },
  ],
  company: [
    { name: "White Paper", href: "https://whitepaper.dcentral.me/" },
    // { name: "Blog", href: "https://blog.dcentral.me/" },
    {
      name: "Jobs",
      href: "https://www.linkedin.com/company/dcentral-me/jobs/",
    },
    // { name: "Press", href: "#" },
  ],
  legal: [
    { name: "Privacy", href: "/policies/privacy" },
    { name: "Copyright", href: "/policies/copyright" },
    { name: "Terms", href: "/policies/terms" },
  ],
};

export default function Footer() {
  const [contactModal, setContactModal] = useState(false);
  const [advertiseModal, setAdvertiseModal] = useState(false);
  const [bugModal, setBugModal] = useState(false);

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertType, setAlertType] = useState("default");

  return (
    <footer className="sm:ml-[80px]" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 pb-8 pt-16  border-t border-dark-default-1 sm:pt-24 lg:px-8 lg:pt-16 lg:mt-20">
        <div className="lg:grid lg:grid-cols-3 xl:gap-8">
          <div className="space-y-8 sm:ml-[24px]">
            <LogoAndSocialMedia />
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 lg:col-span-2 xl:mt-0 sm:ml-[100px]">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-600 leading-6 text-white">
                  Solutions
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <div key={item.name}>
                      <Link
                        href={item.href}
                        target={item.target}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                  <div
                    onClick={() => setAdvertiseModal(true)}
                    className="cursor-pointer text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    Advertise
                  </div>
                </div>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-600 leading-6 text-white">
                  Support
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <div key={item.name}>
                      <Link
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                  <div
                    onClick={() => setContactModal(true)}
                    className="cursor-pointer text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    Contact Us
                  </div>
                </div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-600 leading-6 text-white">
                  Company
                </h3>
                <div className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <div key={item.name}>
                      <Link
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-600 leading-6 text-white">Legal</h3>
                <div className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <div key={item.name}>
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                        aria-label={item.name}
                      >
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Newsletter />
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-sm text-center leading-5 text-light-gray">
            &copy; 2022 - 2024 Dcentral Inc. All rights reserved.
          </p>
        </div>
        <div className="pt-8 text-light-gray pb-4 text-sm text-center justify-center flex items-center gap-x-1 leading-5 ">
          Made with{" "}
          <img
            src="/cowboy-hat.png"
            alt="cowboy hat"
            className="w-10 mx-1 h-auto object-contain"
          />
          in the USA <span className="text-[28px]">🇺🇸</span>
        </div>
      </div>
      <Modal open={contactModal} setOpen={setContactModal} width="sm:w-[80%]">
        <ContactUs
          setContactModal={setContactModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setAlertType={setAlertType}
        />
      </Modal>
      {advertiseModal && (
        <AdvertiseOnRodeyo
          open={advertiseModal}
          setOpen={setAdvertiseModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setAlertType={setAlertType}
        />
      )}

      <Alert
        open={snackbar}
        close={() => setSnackbar(false)}
        message={snackbarMessage}
        type={alertType}
      />
    </footer>
  );
}
